var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "carDialogVue", attrs: { id: _vm.titlex } },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: {
            width: "350px",
            "box-shadow": "0px 2px 8px 0px rgba(200, 200, 200, 0.5)",
            "border-radius": "4px"
          }
        },
        [
          _c("i", {
            staticClass: "el-icon-close",
            staticStyle: {
              float: "right",
              padding: "3px 0",
              cursor: "pointer"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.closeDialog()
              }
            }
          }),
          _c("div", { staticClass: "monitor-message" }, [
            _c(
              "div",
              { staticClass: "cars-info", on: { mousedown: _vm.mousedown } },
              [
                _c("ul", { staticClass: "info" }, [
                  _c("li", { staticClass: "card-header" }, [
                    _c("div", { staticStyle: { width: "100%" } }, [
                      _c("img", {
                        attrs: { src: _vm.iconUrl[_vm.data.status] }
                      }),
                      _c(
                        "div",
                        { staticClass: "title", attrs: { title: _vm.titlex } },
                        [_vm._v(" " + _vm._s(_vm.titlex) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          class: _vm.statusText(_vm.data.status),
                          staticStyle: { "font-size": "16px" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.data.status
                                  ? "离线"
                                  : _vm.status[_vm.data.status]
                              ) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("li", { staticStyle: { padding: "0 30px 0 40px" } }, [
                    _c("div", [
                      _c("span", { staticClass: "car-info" }, [
                        _c("i", {
                          staticClass: "iconfont its_driver",
                          attrs: { title: "驾驶员" }
                        })
                      ]),
                      _c(
                        "p",
                        {
                          staticStyle: { "font-size": "12px" },
                          attrs: {
                            title: _vm.data.driverName || "未获取到数据"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.data.driverName || "未获取到数据") +
                              " "
                          )
                        ]
                      )
                    ]),
                    _c("div", [
                      _c("span", { staticClass: "car-info" }, [
                        _c("i", {
                          staticClass: "iconfont its_terminal",
                          attrs: { title: "终端编号" }
                        })
                      ]),
                      _c(
                        "p",
                        {
                          staticStyle: { "font-size": "12px" },
                          attrs: { title: _vm.data.deviceNos || "未获取到数据" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.data.deviceNos || "未获取到数据") +
                              " "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        background: "#f7f7f7",
                        padding: "10px",
                        "margin-top": "5px"
                      }
                    },
                    [
                      _c("li", [
                        _c("span", [_vm._v("时间：")]),
                        _c("p", { attrs: { title: _vm.data.gpsTime } }, [
                          _vm._v(_vm._s(_vm.data.gpsTime))
                        ])
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("行驶速度：")]),
                        _c("p", { attrs: { title: _vm.data.velocity } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.data.velocity ? _vm.data.velocity : 0
                              ) +
                              "公里/时 "
                          )
                        ])
                      ]),
                      _c("li", { staticClass: "position" }, [
                        _c("span", [_vm._v("位置：")]),
                        _c(
                          "p",
                          {
                            staticClass: "street",
                            attrs: { title: _vm.address }
                          },
                          [_vm._v(" " + _vm._s(_vm.address) + " ")]
                        )
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("ACC：")]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.data.accStatus === null ||
                                  _vm.data.accStatus == 1
                                  ? "ACC关"
                                  : "ACC开"
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("定位状态：")]),
                        _c(
                          "p",
                          {
                            staticClass: "street",
                            attrs: {
                              title:
                                (_vm.data.locate === null ||
                                _vm.data.locate === 1
                                  ? "未定位"
                                  : "已定位") +
                                _vm.getLocateText(
                                  _vm.data.gps,
                                  _vm.data.beidou,
                                  _vm.data.glonass,
                                  _vm.data.galileo
                                )
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.data.locate === null ||
                                    _vm.data.locate === 1
                                    ? "未定位"
                                    : "已定位"
                                ) +
                                " " +
                                _vm._s(
                                  _vm.getLocateText(
                                    _vm.data.gps,
                                    _vm.data.beidou,
                                    _vm.data.glonass,
                                    _vm.data.galileo
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("报警名称：")]),
                        _c("span", { staticClass: "text4" }, [
                          _vm._v(_vm._s(_vm.data.alarmType))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "buttons" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small", sort: "grey" },
                          on: { click: _vm.toPlayVideo }
                        },
                        [
                          _c("i", { staticClass: "iconfont its_shipin" }),
                          _vm._v("视频")
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small", sort: "grey" },
                          on: { click: _vm.toPlayHisVideo }
                        },
                        [
                          _c("i", { staticClass: "iconfont its_lishishipin" }),
                          _vm._v("历史")
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small", sort: "grey" },
                          on: { click: _vm.toPlayTril }
                        },
                        [
                          _c("i", { staticClass: "iconfont its_guijihuifang" }),
                          _vm._v("轨迹")
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }